import React, {Component} from 'react';
import Section from './../Layout/Section';
import GalleryItem from './../Layout/GalleryItem';

class Gallery extends Component {
    render() {
        return <Section centerText={true} class="section-padding-t gray-bg" style={this.props.style}>
            <h2>{this.props.title}</h2>
            <div className="container-fluid">
                <div className="portfolio-grid-fit">
                    {this.props.images.map(function (image) {
                        return <GalleryItem image={image.image} caption={image.caption}/>
                    })}
                </div>
                {this.props.children}
            </div>
        </Section>
    }
}

export default Gallery;