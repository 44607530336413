import React, {Component} from 'react';

class TextArea extends Component {
    render() {
        return <textarea name={this.props.name} className="input-md form-full" rows={this.props.rows} cols={this.props.cols}
                         placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}>

        </textarea>
    }
}

export default TextArea;