import React, {Component} from 'react';

class Slide extends Component {
    render() {
        return <li data-x="400" data-y="100" data-speed="600" data-start="800" data-transition="slideleft">
            <img src={this.props.image} alt=""/>

            <div className="tp-caption lfb ltt tp-resizeme sc-title start" data-x="center" data-hoffset="0"
                 data-y="center" data-voffset="-125" data-speed="600" data-start="500" data-easing="Power4.easeOut"
                 data-splitin="none" data-splitout="none" data-elementdelay="0.01" data-endelementdelay="0.1" data-endspeed="500"
                 data-endeasing="Power4.easeIn">
                <div className="slide-overlay">
                    {this.props.overlay ? this.props.overlay : null}
                </div>
                    <h2 style={{color: '#ffffff', whiteSpace: 'pre-wrap'}}>
                        {this.props.header}
                    </h2>
                <div className="slider-content">
                    {this.props.children}
                </div>
            </div>
        </li>
    }
}

export default Slide;