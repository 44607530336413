import React, {Component} from 'react';
import Section from './../Layout/Section';
import SectionRow from "../Layout/SectionRow";

class About extends Component {
    render() {
        return <Section id="about-us" centerText={true}>
            <h2><span className="text-light">Welcome to</span> The Vault</h2>
            <p>
            Built in 1932 by Edwin Lutyens , an English architect known for imaginatively adapting traditional architectural styles to the 
            requirements of his area. An architectural historian described him as "surely the greatest British architect of the twentieth 
            (or of any other) century". The Vault was the original strong room for the Old Midland Bank. The walls lined with 432 safety 
            deposit boxes, are a reflection of London&#8217;s fortified history in finance and innovation housing bonds, valuables and important documents.
            </p>
            <p>
                The Vault has been transformed into an exquisite room that offers a relaxed, yet sophisticated space to hold memorable parties, 
                corporate events, cocktail masterclasses and more.
            </p>
            <div className="row">
                <SectionRow>
                    <div className="wow fadeIn" data-wow-delay="0.1s" data-wow-duration="2s" style={{
                        visibility: 'visible',
                        animationDuration: '2s'
                    }}>
                        <div className="icon-top">
                            <i className="fa fa-cutlery"></i>
                        </div>
                        <h5>Dining</h5>
                        <p>
                            Choose between our re-envisioned decadent 3 or 5 tier Lutyens&#8217; Menu, or our delicious Canapés to fit any occasion.
                        </p>
                        <p>
                            Please note, when selecting the 3 & 5 Tier À la carte menu, we do require a pre-order two weeks in advance.
                        </p>
                    </div>
                </SectionRow>
                <SectionRow>
                    <div className="wow fadeIn" data-wow-delay="0.2s" data-wow-duration="2s" style={{
                        visibility: 'visible',
                        animationDuration: '2s'
                    }}>
                        <div className="icon-top">
                            <i className="fa fa-glass"></i>
                        </div>
                        <h5>
                            <span className="text-light">Bespoke Cocktail </span>
                            Menus
                        </h5>
                        <p>
                            An exciting new menu, filled with a variety of classic cocktails and a selection of hand-picked wines to accompany 
                            the perfect dining experience.
                        </p>
                    </div>
                </SectionRow>
                <SectionRow>
                    <div className="wow fadeIn" data-wow-delay="0.2s" data-wow-duration="2s" style={{
                        visibility: 'visible',
                        animationDuration: '2s'
                    }}>
                        <div className="icon-top">
                            <i className="fa fa-users"></i>
                        </div>
                        <h5>
                            <span className="text-light">Party </span>
                            Bookings
                        </h5>
                        <p>
                            A unique space perfect to fit any occasion. An unforgettable dining and drinking experience with a beautiful private copper 
                            champagne bar and the latest in Audio/Visual which will ensure to leave your guests inspired.
                        </p>
                    </div>
                </SectionRow>
            </div>
        </Section>
    }
}

export default About;