import React, {Component} from 'react';
import Section from './../Layout/Section';
import SectionRow from "../Layout/SectionRow";

class Filming extends Component {
    render() {
        return <Section id="filming" className="text-center gray-bg">
            <h2>Filming</h2>
            <div className="owl-carousel content-carousel content-slider">
                <div className="item">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-sm-30">
                                <img src="/assets/images/filming.jpg" alt=""/>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <div className="spacer-30"></div>
                                <p style={{float: 'left', paddingLeft: 0}}>
                                    The Vault started life as the main Midland Bank vault and it still retains many of its original features. 
                                    Please contact the team should you want to hire the Vault for filming purposes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    }
}

export default Filming;