import React, { Component } from 'react';
import Section from './../Layout/Section';

class Christmas extends Component {
    render () {
        return <div id="christmas">
            <Section className="text-center">
                <h2>Christmas</h2>
                <div className="owl-carousel content-carousel content-slider">
                    <div className="item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 mb-sm-30">
                                    <img src="/assets/images/christmas.jpg" alt=""/>
                                </div>
                                <div className="col-md-5 offset-md-1">
                                    <div className="spacer-30"></div>
                                    <p>
                                        Spend the most magical time of year with us and host a truly spectacular Christmas event. Known for being the hidden gem of The City, 
                                        a place for high-end indulgence, The Vault has a lot to offer. With an elegant private dining room, seating up to 30 guests across four 
                                        round tables with a choice of two tempting set menus or parties who seek a livelier standing experience, can socialise with elegant canapes and bubbles.
                                    </p>
                                    <p>
                                        Housed in a former bank vault, the private room is spacious and decedent, with a personal champagne bar. Eye-catching lighting to complement the beautiful 
                                        features and create the instant wow-factor that all Christmas parties should elicit.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    }
}

export default Christmas;