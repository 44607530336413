import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return <footer id="footer" className="footer section-padding gray-bg">
            <div className="container text-center">
                <p className="copyright mb-25"><i className="fa fa-copyright"></i>Revolution Bars Group PLC</p>
                <ul className="social">
                    <li>
                        <a href="https://www.facebook.com/The-Vault-Leadenhall-296383464566255">
                            <i className="fa fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/thevaultleadenhall">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    }
}

export default Footer;