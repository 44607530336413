import React, {Component} from 'react';
import Section from './../Layout/Section';
import Features from "./Features";

class Meetings extends Component {
    render() {
        return <div id="meetings" className="gray-bg">
            <Section className="text-center gray-bg">
                <h2>Meetings</h2>
                <p>
                    Choose something a little different when it comes to your next meeting.
                    The Vault can host meetings in many different seated and standing formats.
                </p>
                <p>
                    With all bookings, you will have a dedicated coordinator to ensure that everything runs smoothly. We offer full use of audio-visual equipment including screens and a quality sound system.
                </p>
            </Section>
            <div className="spacer-15 gray-bg"></div>
            <div className="owl-carousel content-carousel content-slider">
                <div className="item">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-sm-30">
                                <img src="/assets/images/Vault_Meetings.png" alt=""/>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <div className="row justify-content-center" style={{textAlign: 'center'}}>
                                    <h3>Business & Team Meetings</h3>
                                    <div className="spacer-15"></div>
                                    <div className="meetings-list-column">
                                        <ul className="meetings-list">
                                            <p>Full Day (9am - 6pm) <br/>£45 Per Person</p>
                                            <li>Fresh Fruit & Pastries</li>
                                            <li>
                                                Bacon or Sausage breakfast sandwiches <br/>
                                                <small>(V/VE options available)</small>
                                            </li>
                                            <li>Unlimited hot drinks & soft drinks</li>
                                            <li>Buffet or a meal each</li>
                                            <li>Wine, Beer, Spirit & mixer or cocktail</li>
                                        </ul>
                                    </div>
                                    <div className="meetings-list-column">
                                        <ul className="meetings-list">
                                            <p>Half Day (12noon – 5pm) <br/>£30 Per Person</p>
                                            <li>Fresh Fruit & Pastries</li>
                                            <li>Unlimited hot drinks & soft drinks</li>
                                            <li>Light Buffet: <br/>
                                                Cheeseburger sliders, Crispy fried chicken wrap, Loaded nachos, 
                                                Skinny fries, Margherita Pizza, Chocolate brownie bites. <br/>
                                                <small>(V/VE options available)</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="spacer-15"></div>
                                <div className='row justify-content-center'>
                                    <a style={{marginTop: 8}} className="btn btn-md btn-black" href="#contact-form">Enquire Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-15"></div>
        </div>
    }
}


export default Meetings;