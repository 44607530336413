import React, {Component} from 'react';
import Gallery from './Gallery';
import Section from './../Layout/Section';

class Food extends Component {
    render() {
        return <div id="private-dining">
            <Section centerText={true} className="white-bg">
                <h2>Private Dining</h2>
                <div className="owl-carousel content-carousel content-slider">
                    <div className="item">
                        <div className="spacer-15"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 mb-sm-30">
                                    <img src="/assets/images/Vault_Dining.png" alt=""/>
                                </div>
                                <div className="col-md-5 offset-md-1" style={{paddingTop: 20}}>
                                    <p>
                                        With access to a private bar, you can enjoy our 3 & 5 Tier À la carte menu in style, privacy and comfort. You have the option to add a Champagne, 
                                        prosecco or cocktail reception to start your occasion right. Why not elevate your reception further with a selection of canapés. Please note, 
                                        when selecting the 3 & 5 Tier À la carte menu, we do require a pre-order two weeks in advance.
                                    </p>
                                    <a className="btn btn-md btn-black" href="https://thevault.s3.eu-west-2.amazonaws.com/vault_food_menu.pdf">Download Food Menu</a>
                                    <div className="spacer-30"/>
                                    <a className="btn btn-md btn-black" href="https://thevault.s3.eu-west-2.amazonaws.com/vault_drinks_menu.pdf">Download Drinks Menu</a>
                                    <div className="spacer-30"/>
                                    <a href="https://book.revolution-bars.co.uk/allergens/food/181">Click here to view allergen information</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    }
}

export default Food;