import React, {Component} from 'react';

class Logo extends Component {
    constructor (props) {
        super(props);
        this.state = {}
    }
    render () {
        return <div className="logo">
                <a href="/">
                    <img src="../../assets/images/logo.png" alt=""/>
                </a>
        </div>
    }
}

export default Logo;