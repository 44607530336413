import React, {Component} from 'react';
import Section from './../Layout/Section';
import SectionRow from './../Layout/SectionRow';

class ContactInfo extends Component {
    render() {
        return <Section id="contact-info" centerText={true} >
            <div className="row">
                <SectionRow>
                    <i className="fa fa-map-marker"></i>
                    <h5 className="features-title">Address</h5>
                    <p>
                        <a href="https://www.google.com/maps/place/Leadenhall+St,+London+EC3V+4QT/@51.5137465,-0.0851917,17z/data=!3m1!4b1!4m5!3m4!1s0x48760352bd6229cb:0xd573d1a8fb64d031!8m2!3d51.5137465!4d-0.083003">
                            The Vault deep within Revolution Leadenhall, <br/>140 Leadenhall St, London EC3V 4QT
                        </a>
                    </p>
                </SectionRow>
                <SectionRow>
                    <i className="fa fa-clock-o"></i>
                    <h5 className="features-title">Opening Hours</h5>
                    <p>
                        Bookings available to start from 8am until 2.30am
                    </p>
                </SectionRow>
                <SectionRow>
                    <i className="fa fa-info-circle"></i>
                    <h5 className="features-title">Contact Information</h5>
                    <p>
                        <i className="fa fa-phone"></i> 020 7621 9955 <br/>
                        <i className="fa fa-envelope"></i> <a href="mailto:thevaultleadenhall@revolution-bars.co.uk">thevaultleadenhall@revolution-bars.co.uk</a>
                    </p>
                </SectionRow>
            </div>
        </Section>
    }
}

export default ContactInfo;