import React, {Component} from 'react';
import Header from './../Site/Layout/Header';
import Footer from './../Site/Layout/Footer';
import Slider from './../Site/Slider/Slider';
import ContactInfo from './../Site/Section/ContactInfo';
import ContactForm from "./Section/ContactForm";
import About from './Section/About';
import Food from './../Site/Section/Food';
import Meetings from './../Site/Section/Meetings';
import Parties from "./Section/Parties"; 
import Filming from "./Section/Filming";
import Christmas from './Section/Christmas';

class Index extends Component {
    render() {
        return <div id="page-wrapper">
            <div className="wrapper">
                <Header/>
                <Slider/>
                <About/>
                <Meetings/>
                <Food/>
                <Parties/>
                <Christmas/>
                <Filming/>
                <ContactForm/>
                <ContactInfo/>
                <Footer/>
            </div>
        </div>
    }
}

export default Index;