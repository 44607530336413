import React, {Component} from 'react';
import Logo from '../Layout/Logo.js';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return <header id="header" className="header">
            <div className="header-inner">
                <Logo/>
                <div className="nav-menu-icon">
                    <a>
                    <i className="fa fa-bars"/>
                    </a>
                </div>
                <div className="nav-menu singlepage-nav">
                    <ul className="nav-menu-inner">
                        <li><a href="#about-us">About</a></li>
                        <li><a href="#meetings">Meetings</a></li>
                        <li><a href="#private-dining">Private Dining</a></li>
                        <li><a href="#parties">Parties</a></li>
                        <li><a href="#filming">Filming</a></li>
                        <li><a href="#contact-form">Book</a></li>
                        <li><a href="#contact-info">Contact Info</a></li>
                    </ul>
                </div>
            </div>
        </header>
    }
}

export default Header;