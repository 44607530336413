import React, {Component} from 'react';
import Section from './../Layout/Section';
import Field from './../../Form/Field';
import Text from './../../Form/Element/Text';
import TextArea from './../../Form/Element/TextArea';
import Checkbox from './../../Form/Element/Checkbox';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                forename: '',
                surname: '',
                email: '',
                phone: '',
                message: '',
                email_opt_in: false,
                sms_opt_in: false,
                phone_opt_in: false
            },
            errors: [],
            loading: false,
            message: null
        }
    }
    handleChange(e) {
        let current = this.state.form;

        current[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        this.setState({
            form: current
        });
    }
    handleSubmit(e) {
        e.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        this.setState({
            loading: true
        });

        let form = this.state.form;
        let body = [];

        for (let i in form) {
            body.push(encodeURIComponent(i) + '=' + encodeURIComponent(form[i]));
        }

        body = body.join('&');

        let el = document.getElementById('success');

        return fetch('/api/rest/vault-website/contact-form', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            method: 'POST',
            body: body
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            if (json.success) {
                this.setState({
                    message: '<p style="color: green">Thank you for getting in touch, we will get back to you as soon as possible</p>',
                    loading: false,
                    form: {
                        forename: '',
                        surname: '',
                        email: '',
                        phone: '',
                        message: '',
                        email_opt_in: false,
                        phone_opt_in: false,
                        sms_opt_in: false
                    }
                })
            }
         }.bind(this)
        ).catch(function (error) {
            this.setState({
                message: '<p style="color: red">Sorry, there was a problem processing your request. Please try again</p>',
                loading: false
            })
        }.bind(this));
    }
    validateForm () {
        let errors = [];
        if (this.state.form.forename === '') {
            errors.push({
                field: 'forename',
                message: 'First Name is required'
            })
        }

        if (this.state.form.surname === '') {
            errors.push({
                field: 'surname',
                message: 'Last Name is required'
            })
        }

        if (this.state.form.email === '') {
            errors.push({
                field: 'email',
                message: 'Email is required'
            })
        }

        if (this.state.form.message === '') {
            errors.push({
                field: 'message',
                message: 'Message is required'
            })
        }

        if (this.state.form.phone === '') {
            errors.push({
                field: 'phone_number',
                message: 'Phone Number is required'
            });
        }

        if (isNaN(this.state.form.phone)) {
            errors.push({
                field: 'phone_number',
                message: 'This field must be a valid Phone Number'
            });
        }

        this.setState({
            errors: errors
        });

        return errors.length === 0;
    }
    getError(field) {
        let error = this.state.errors.find(function (error) {
            return error.field === field
        });

        return (error) ? error.message : null
    }
    render() {
        return <Section id="contact-form" className="section-padding dark-bg overlay-dark" centerText={true}>
            <h2>Contact Us</h2>
            <p>
                Like what you see? Why not get in touch...to make an enquiry
            </p>
            <br/>
            <div className="row text-center">
                <div className="col-md-8 offset-md-2">
                    <div className="wow fadeIn" data-wow-delay="0.1s" data-wow-duration="2s" style={{
                        visibility: 'visible',
                        animationDuration: '2s'
                    }}><p dangerouslySetInnerHTML={{__html: this.state.message ? this.state.message : null}}/></div>
                    <form id="contact" className="row contact-form">
                        <div className="col-md-6 text-center">
                            <Field errors={this.getError('forename')}>
                                <Text name="forename" value={this.state.form.forename} placeholder="First Name" onChange={this.handleChange.bind(this)}/>
                            </Field>
                            <Field errors={this.getError('surname')}>
                                <Text name="surname" value={this.state.form.surname} placeholder="Last Name" onChange={this.handleChange.bind(this)}/>
                            </Field>
                            <Field errors={this.getError('email')}>
                                <Text name="email" value={this.state.form.email} placeholder="Email" onChange={this.handleChange.bind(this)}
                                      isEmail={true} disabled={this.state.loading}/>
                            </Field>
                            <Field errors={this.getError('phone_number')}>
                                <Text name="phone" value={this.state.form.phone} placeholder="Phone Number" onChange={this.handleChange.bind(this)}/>
                            </Field>
                        </div>
                        <div className="col-md-6">
                            <Field errors={this.getError('message')}>
                                <TextArea name="message" placeholder="Your Message" onChange={this.handleChange.bind(this)} value={this.state.form.message} disabled={this.state.loading}/>
                            </Field>
                        </div>
                        <div>
                        <p style={{paddingLeft: 15, paddingTop: 10, paddingBottom: 5}}>By ticking the below, I consent to receive any Sales or Marketing contact from Revolution Bars</p>
                        <ul style={{listStyle: 'none'}} className="opt-in-group">
                            <li><Checkbox name="email_opt_in" onChange={this.handleChange.bind(this)} checked={this.state.form.email_opt_in}/><span className="opt_in">Email</span></li><br/>
                            <li><Checkbox name="sms_opt_in" onChange={this.handleChange.bind(this)} checked={this.state.form.sms_opt_in}/> <span className="opt_in">SMS Messages</span></li><br/>
                            <li><Checkbox name="phone_opt_in" onChange={this.handleChange.bind(this)} checked={this.state.form.phone_opt_in}/> <span className="opt_in">Telephone Calls</span></li>
                        </ul>
                        </div>
                        <div className="col-md-12 text-center">
                            <button className="btn-contact-submit btn btn-md btn-color" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </Section>
    }
}

export default ContactForm;