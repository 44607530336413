import React, {Component} from 'react';

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            className: 'section-padding'
        }
    }
    render() {
        return <section id={this.props.id} className={this.props.className ? this.props.className : this.state.className} data-background-img={this.props.image} style={this.props.style}>
            <div className={this.props.centerText ? 'container text-center' : 'container'}>
                {this.props.children}
            </div>
        </section>
    }
}

export default Section;