import React, {Component} from 'react';

class SectionRow extends Component {
    render() {
       return <div className="col-md-4 mb-sm-30">
           <div className="alt-icon-sm-top">
               {this.props.children}
           </div>
        </div>
    }
}

export default SectionRow;