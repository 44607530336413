import React, {Component} from 'react';

class Text extends Component {
    render() {
        return <input className="input-md form-full" type={this.props.isEmail ? 'email' : 'text'}
                      name={this.props.name} placeholder={this.props.placeholder}
                      value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}/>
    }
}

export default Text;