import React, {Component} from 'react';

class Field extends Component {
    render() {
        return <div className="form-field-wrapper">
            {typeof this.props.errors != 'undefined' ? <p style={{ color: 'red' }}>{this.props.errors}</p> : null}
            {this.props.children}
        </div>
    }
}

export default Field;