import React, {Component} from 'react';

class GalleryItem extends Component {
    render() {
        let className = "portfolio-item";
        return <div className={this.props.caption ? className + ' row gallery-popup' : className}>
            <div className="portfolio-box">
                <a className="portfolio-image-wrap" href="/">
                    <img src={this.props.image}/>
                </a>
                {this.props.caption ? <div className="portfolio-caption">
                    <div className="portfolio-caption-tb">
                        <div className="portfolio-caption-tb-cell">
                            {this.props.caption.body}
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    }
}

export default GalleryItem;