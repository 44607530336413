import React, {Component} from 'react';
import Section from './../Layout/Section';
import Features from "./Features";

class Parties extends Component {
    render() {
        return <div id="parties" className="gray-bg">
            <Section className="text-center gray-bg">
                <h2>Parties</h2>
            </Section>
            <div className="spacer-15 gray-bg"></div>
            <div className="owl-carousel content-carousel content-slider">
                <div className="item">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-sm-30">
                                <img src="/assets/images/Vault_Parties.jpg" alt=""/>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <h3>Exclusive Party Space</h3>
                                <div className="spacer-15"></div>
                                <ul>
                                    <li>Up to 70 guests</li>
                                    <li>Full use of audio visual equipment</li>
                                    <li>Own private bartender</li>
                                    <li>Options of Champagne, prosecco or cocktail reception</li>
                                    <li>Buffet or Canapé Reception</li>
                                </ul>
                                <div className="spacer-15"></div>
                                <a className="btn btn-md btn-black" href="#contact-form">Enquire Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-15"></div>
        </div>
    }
}


export default Parties;