import React, {Component} from 'react';
import Section from './../Layout/Section';

class Features extends Component {
    render() {
        return <Section id="features" className="section-padding bg-image overlay-dark dark-bg text-center" image="../../assets/images/vault-7.jpg">
            <div className="owl-carousel test-carousel">
                <div>
                    <p className="max-width-700 lead">
                        Cocktail Masterclasses
                    </p>
                </div>
                <div>
                    <p className="max-width-700 lead">
                        Some other feature
                    </p>
                </div>
                <div>
                    <p className="max-width-700 lead">Even more features</p>
                </div>
            </div>
        </Section>
    }
}

export default Features;