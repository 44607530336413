import React, {Component} from 'react';
import Slide from '../Slider/Slide';

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: [
                {
                    header: 'Welcome To The Vault \n Leadenhall',
                    image: '../../assets/images/Vault_1.png',
                    overlay: <img src="../../assets/images/vault-logo-450.png"/>,
                    body: ''
                },
                {
                    header: 'Business and private events',
                    image: '../../assets/images/Vault_2.png',
                    body: ''
                },
                {
                    header: 'View Our Menu',
                    image: '../../assets/images/Vault_3.jpg',
                    body: <a className="btn btn-md btn-black" href='#private-dining'>View Our Menu</a>
                }
            ]
        }
    }
    render() {
        return <div className="intro-tp-banner">
            <ul>
                {this.state.slides.map(function (slide, idx) {
                    return <Slide key={idx} header={slide.header} image={slide.image} overlay={slide.overlay ? slide.overlay : null}>{slide.body}</Slide>
                })}
            </ul>
        </div>
    }
}

export default Slider;